<template>
	<div class="shouye">
		<!-- <add-activity></add-activity> -->
	</div>
</template>

<script>
	// import addActivity from '@/view/activity/addActivity/chooseActivityItem'
	export default {
		name: "Index",
		data() {
			return {};
		},
		// components: {
		//   addActivity
		// },
		created() {},
		mounted() {

		},
		methods: {

		},
	};
</script>

<style lang="scss" scoped>
	.shouye{
		position: absolute;
		left: 0;
		top: 0;
		display: block;
		width: 100%;
		height: 100%;
		background-image: url(../assets/imgs/shouyebg.jpg);
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
	}
</style>
